$(document).ready(function() {

/*  Main variables for the entire application
 * ----------------------------------------------------------------------------------------------------------------- */

    // $('body').bind({
    //     paste : function(){
    //         console.log();
    //     },
    // });


    document.addEventListener("paste", function (e) {
        var pastedText = undefined;
        if (window.clipboardData && window.clipboardData.getData) { // IE
            pastedText = window.clipboardData.getData('Text');
        } else if (e.clipboardData && e.clipboardData.getData) {
            pastedText = e.clipboardData.getData('text/plain');
        }

        if(!$("input, textarea").is(":focus")) {
            var video_id = pastedText.split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if(ampersandPosition != -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }

            if(video_id !== '') {
                swal({
                    title: "Lien Youtube détecté",
                    text: "Vous venez de copier-coller un lien Youtube sur la page, souhaitez-vous l'ajouter à vos médias pour cet élément?<br><br>Vidéo copiée: <iframe width='444' height='250' src='https://www.youtube.com/embed/"+video_id+"?rel=0&amp;controls=0&amp;showinfo=0' frameborder='0' allowfullscreen></iframe>",
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Oui, volontiers!',
                    cancelButtonText: 'Non',
                    html: true,
                    closeOnConfirm: true
                });
            }
        }
        return false;
    });


/*------------------------------------------------------
    Chartjs
------------------------------------------------------*/
    // var data = {
    //     labels: ["January", "February", "March", "April", "May", "June", "July"],
    //     datasets: [
    //         {
    //             label: "My First dataset",
    //             fill: false,
    //             lineTension: 0.1,
    //             backgroundColor: "rgba(75,192,192,0.4)",
    //             borderColor: "rgba(75,192,192,1)",
    //             borderCapStyle: 'butt',
    //             borderDash: [],
    //             borderDashOffset: 0.0,
    //             borderJoinStyle: 'miter',
    //             pointBorderColor: "rgba(75,192,192,1)",
    //             pointBackgroundColor: "#fff",
    //             pointBorderWidth: 1,
    //             pointHoverRadius: 5,
    //             pointHoverBackgroundColor: "rgba(75,192,192,1)",
    //             pointHoverBorderColor: "rgba(220,220,220,1)",
    //             pointHoverBorderWidth: 2,
    //             pointRadius: 1,
    //             pointHitRadius: 10,
    //             data: [65, 59, 80, 81, 56, 55, 40],
    //             spanGaps: false,
    //         }
    //     ]
    // }
    // var context = document.querySelector('#graph');

    // new Chart(context.getContext('2d') , {
    //     type: "bar",
    //     data: data, 
    // });


/*------------------------------------------------------
    Cal Heatmap
------------------------------------------------------*/
    if($('#cal-heatmap').length >= 1) {
        var cal = new CalHeatMap();
        moment.locale('fr', {
            months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
            monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
            monthsParseExact : true,
            weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
            weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
            weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
            weekdaysParseExact : true,
            longDateFormat : {
                MMMM : 'D',
                LT : 'HH:mm',
                LTS : 'HH:mm:ss',
                L : 'DD/MM/YYYY',
                LL : 'D MMMM YYYY',
                LLL : 'D MMMM YYYY HH:mm',
                LLLL : 'dddd D MMMM YYYY HH:mm'
            },
            calendar : {
                sameDay : '[Aujourd’hui à] LT',
                nextDay : '[Demain à] LT',
                nextWeek : 'dddd [à] LT',
                lastDay : '[Hier à] LT',
                lastWeek : 'dddd [dernier à] LT',
                sameElse : 'L'
            },
            relativeTime : {
                future : 'dans %s',
                past : 'il y a %s',
                s : 'quelques secondes',
                m : 'une minute',
                mm : '%d minutes',
                h : 'une heure',
                hh : '%d heures',
                d : 'un jour',
                dd : '%d jours',
                M : 'un mois',
                MM : '%d mois',
                y : 'un an',
                yy : '%d ans'
            },
            dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
            ordinal : function (number) {
                return number + (number === 1 ? 'er' : 'e');
            },
            meridiemParse : /PD|MD/,
            isPM : function (input) {
                return input.charAt(0) === 'M';
            },
            // In case the meridiem units are not separated around 12, then implement
            // this function (look at locale/id.js for an example).
            // meridiemHour : function (hour, meridiem) {
            //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
            // },
            meridiem : function (hours, minutes, isLower) {
                return hours < 12 ? 'PD' : 'MD';
            },
            week : {
                dow : 1, // Monday is the first day of the week.
                doy : 4  // The week that contains Jan 4th is the first week of the year.
            }
        });

        cal.init({
            start: new Date($('#cal-heatmap').data('year'), 0),
            range: 12,
            domain: "month",
            domainLabelFormat: function(date) {
                return moment(date).format("MMMM");
            },
            subDomain: "day",
            tooltip: false,
            domainDynamicDimension: true,
            data: "/cockpit/contributions-datas",
            itemName: ["contribution", "contributions"],
            cellLabel: {
                empty: "Aucune données pour le {date}",
                filled: "il y a eu {count} {name} le {date}"
            },
            subDomainDateFormat: function(date) {
                return moment(date).format("LL");
            }
        });
    }


/*------------------------------------------------------
    Redactor
------------------------------------------------------*/
    $('.redactor').redactor({
        plugins: ['source'],
        minHeight: 120,
        lang: 'fr'
    });


/*------------------------------------------------------
    Datetimepicker
------------------------------------------------------*/
    $('.datetime-picker').datetimepicker({
        format:'Y-m-d H:i:s',
        lang:'ru',
        closeOnDateSelect: true
    });


/*------------------------------------------------------
    Visually create the slug of the element
------------------------------------------------------*/
    $('#slug').slugify('#title');


/*------------------------------------------------------
    Visually render the tags
------------------------------------------------------*/
    $('.keywords').tagsInput({
        width: 'auto',
        height: 'auto',
        'defaultText':'ajouter',
    });

/*------------------------------------------------------
    Chosen select
------------------------------------------------------*/
    $(".chosen-select").chosen({no_results_text: "Oops, aucun résultat trouvé!"});

/*------------------------------------------------------
    Switch
------------------------------------------------------*/
    $('input.switch').livequery(function() {
        $(this).lc_switch(null, null);
    });

    // triggered each time a field changes status
    $('body').delegate('.lcs_check', 'lcs-statuschange', function() {
        var status = ($(this).is(':checked')) ? 1 : 0;
        var itemid = $(this).data('itemid');
        var ressource = $(this).data('ressource');

        var token = document.getElementById("token").getAttribute('token');

        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (xhttp.readyState == 4 && xhttp.status == 200) {
                console.log('youhou');
            }
        };

        xhttp.open("POST", "updatestatus?status="+status+"&itemid="+itemid+"&ressource="+ressource, true);

        if(token) {
            xhttp.setRequestHeader('X-CSRF-TOKEN', token); // adds directly to the XmlHttpRequest Object
        }

        xhttp.send();
    });


/*------------------------------------------------------
    Textarea counter
------------------------------------------------------*/
    if($('#count_message').length > 0) {
        var text_max = 160;
        $('#count_message').html(text_max - $('#text').val().length + ' caractères restants');

        $('#text').keyup(function() {
            var text_length = $('#text').val().length;
            var text_remaining = text_max - text_length;

            $('#count_message').html(text_remaining + ' caractères restants');
        });
    }

/*------------------------------------------------------
    DataTable
------------------------------------------------------*/
    if($("#data-table").length !== 0) {
        var route = $('#data-table').data('route');
        var nameField = $('#data-table').data('namefield');

        var table = $('#data-table').DataTable({
            processing: true,
            serverSide: true,
            responsive: true,
            responsive: {
                created_at: false,
                updated_at: false,
            },
            ajax: route,
            columns: [
                { data: 'id', name: 'id', orderable: true, searchable: true },
                { data: nameField, name: nameField, orderable: true, searchable: true },
                { data: 'created_at', name: 'created_at', orderable: false, searchable: false },
                { data: 'updated_at', name: 'updated_at', orderable: false, searchable: false },
                { data: 'action', name: 'action', orderable: false, searchable: false}
            ],
            iDisplayLength: 50,
            stateSave: true,
            bJQueryUI: false,
            sPaginationType: "full_numbers",
            order: [[0, 'desc']],
            oLanguage: {
                "sProcessing":     "Traitement en cours...",
                "sSearch":         "",
                "sLengthMenu":     "Afficher _MENU_ &eacute;l&eacute;ments",
                "sInfo":           "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                "sInfoEmpty":      "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
                "sInfoFiltered":   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                "sInfoPostFix":    "",
                "sLoadingRecords": "Chargement en cours...",
                "sZeroRecords":    "Aucun &eacute;l&eacute;ment &agrave; afficher",
                "sEmptyTable":     "Aucune donn&eacute;e disponible dans le tableau",
                "oPaginate": {
                    "sFirst":      "Premier",
                    "sPrevious":   "Pr&eacute;c&eacute;dent",
                    "sNext":       "Suivant",
                    "sLast":       "Dernier"
                },
                "oAria": {
                    "sSortAscending":  ": activer pour trier la colonne par ordre croissant",
                    "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
                }
            },
        });
    }

    $('#example-select-all').on('click', function(){
        // Get all rows with search applied
        var rows = table.rows({ 'search': 'applied' }).nodes();
        // Check/uncheck checkboxes for all rows in the table
        $('input[type="checkbox"]', rows).prop('checked', this.checked);
   });

   // Handle click on checkbox to set state of "Select all" control
   $('#data-table tbody').on('change', 'input[type="checkbox"]', function(){
        $('.delete-multiple').show();
        // If checkbox is not checked
        if(!this.checked){
            var el = $('#example-select-all').get(0);

            $('.delete-multiple').hide();

            // If "Select all" control is checked and has 'indeterminate' property
            if(el && el.checked && ('indeterminate' in el)){
               // Set visual state of "Select all" control 
               // as 'indeterminate'
               el.indeterminate = true;
            }
        }
   });


   // Handle form submission event
   // $('#frm-example').on('submit', function(e) {
   //      var form = this;

   //      // Iterate over all checkboxes in the table
   //      table.$('input[type="checkbox"].checkable').each(function(){
   //          // If checkbox doesn't exist in DOM
   //          if(!$.contains(document, this)){
   //              // If checkbox is checked
   //              if(this.checked){
   //                  // Create a hidden element
   //                  $(form).append(
   //                      $('<input>')
   //                          .attr('type', 'hidden')
   //                          .attr('name', this.name)
   //                          .val(this.value)
   //                  );
   //              }
   //          } 
   //      });

   //      console.log($(form).serialize());

   //      e.preventDefault();
   // });

/*------------------------------------------------------
    Stats datatable
------------------------------------------------------*/
   if($(".stats-table").length !== 0) {
        $('.stats-table').DataTable({
            iDisplayLength: 15,
            bJQueryUI: false,
            sPaginationType: "full_numbers",
            bLengthChange : false,
            bFilter: false,
            oLanguage: {
                "sProcessing":     "Traitement en cours...",
                "sSearch":         "",
                "sLengthMenu":     "Afficher _MENU_ &eacute;l&eacute;ments",
                "sInfo":           "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                "sInfoEmpty":      "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
                "sInfoFiltered":   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                "sInfoPostFix":    "",
                "sLoadingRecords": "Chargement en cours...",
                "sZeroRecords":    "Aucun &eacute;l&eacute;ment &agrave; afficher",
                "sEmptyTable":     "Aucune donn&eacute;e disponible dans le tableau",
                "oPaginate": {
                    "sFirst":      "Premier",
                    "sPrevious":   "Pr&eacute;c&eacute;dent",
                    "sNext":       "Suivant",
                    "sLast":       "Dernier"
                },
                "oAria": {
                    "sSortAscending":  ": activer pour trier la colonne par ordre croissant",
                    "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
                }
            },
        });
   }

/*------------------------------------------------------
    Filler upload
------------------------------------------------------*/
    var token = document.getElementById("token").getAttribute('token');
    var resource = $('#filer_input2').data('resource');
    var resourceid = $('#filer_input2').data('resourceid');
    var extensions = $('#filer_input2').data('extensions');

    $("#filer_input2").filer({
        limit: 20,
        maxSize: 200,
        extensions: ['jpg','jpeg','png','pdf'],
        changeInput: '<div class="jFiler-input-dragDrop"><div class="jFiler-input-inner"><div class="jFiler-input-icon"><i class="icon-jfi-cloud-up-o"></i></div><div class="jFiler-input-text"><h3>Glissez & Déposez vos fichiers ici</h3> <span style="display:inline-block; margin: 15px 0">ou</span></div><a class="jFiler-input-choose-btn btn blue">Cliquez ici</a></div></div>',
        showThumbs: true,
        theme: "dragdropbox",
        templates: {
            box: '<ul class="jFiler-items-list jFiler-items-grid"></ul>',
            item: '<li class="jFiler-item">\
                        <div class="jFiler-item-container">\
                            <div class="jFiler-item-inner">\
                                <div class="jFiler-item-thumb">\
                                    <div class="jFiler-item-status"></div>\
                                    <div class="jFiler-item-thumb-overlay">\
                                        <div class="jFiler-item-info">\
                                            <div style="display:table-cell;vertical-align: middle;">\
                                                <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name}}</b></span>\
                                                <span class="jFiler-item-others">{{fi-size2}}</span>\
                                            </div>\
                                        </div>\
                                    </div>\
                                    {{fi-image}}\
                                </div>\
                                <div class="jFiler-item-assets jFiler-row">\
                                    <ul class="list-inline pull-left">\
                                        <li>{{fi-progressBar}}</li>\
                                    </ul>\
                                    <ul class="list-inline pull-right">\
                                        <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                                    </ul>\
                                </div>\
                            </div>\
                        </div>\
                    </li>',
            itemAppend: '<li class="jFiler-item">\
                            <div class="jFiler-item-container">\
                                <div class="jFiler-item-inner">\
                                    <div class="jFiler-item-thumb">\
                                        <div class="jFiler-item-status"></div>\
                                        <div class="jFiler-item-thumb-overlay">\
                                            <div class="jFiler-item-info">\
                                                <div style="display:table-cell;vertical-align: middle;">\
                                                    <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name}}</b></span>\
                                                    <span class="jFiler-item-others">{{fi-size2}}</span>\
                                                </div>\
                                            </div>\
                                        </div>\
                                        {{fi-image}}\
                                    </div>\
                                    <div class="jFiler-item-assets jFiler-row">\
                                        <ul class="list-inline pull-left">\
                                            <li><span class="jFiler-item-others">{{fi-icon}}</span></li>\
                                        </ul>\
                                        <ul class="list-inline pull-right">\
                                            <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                                        </ul>\
                                    </div>\
                                </div>\
                            </div>\
                        </li>',
            progressBar: '<div class="bar"></div>',
            itemAppendToEnd: false,
            canvasImage: true,
            removeConfirmation: true,
            _selectors: {
                list: '.jFiler-items-list',
                item: '.jFiler-item',
                progressBar: '.bar',
                remove: '.jFiler-item-trash-action'
            }
        },
        uploadFile: {
            url: "/cockpit/upload-medias",
            data : { 'X-CSRF-TOKEN' : token, 'resource' : resource, 'resourceid': resourceid },
            type: 'POST',
            enctype: 'multipart/form-data',
            synchron: true,
            beforeSend: function(){},
            success: function(data, itemEl, listEl, boxEl, newInputEl, inputEl, id){
                var parent = itemEl.find(".jFiler-jProgressBar").parent(),
                    new_file_name = JSON.parse(data),
                    filerKit = inputEl.prop("jFiler");

                filerKit.files_list[id].name = new_file_name;

                itemEl.find(".jFiler-jProgressBar").fadeOut("slow", function(){
                    $("<div class=\"jFiler-item-others text-success\"><i class=\"icon-jfi-check-circle\"></i> Succès</div>").hide().appendTo(parent).fadeIn("slow");
                });
            },
            error: function(el){
                var parent = el.find(".jFiler-jProgressBar").parent();
                el.find(".jFiler-jProgressBar").fadeOut("slow", function(){
                    $("<div class=\"jFiler-item-others text-error\"><i class=\"icon-jfi-minus-circle\"></i> Erreur</div>").hide().appendTo(parent).fadeIn("slow");
                });
            },
            statusCode: null,
            onProgress: null,
            onComplete: null
        },
        dialogs: {
            alert: function(text) {
                swal({
                    type: "warning",
                    title: 'Woops',
                    text: text,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Okay',
                    closeOnConfirm: true
                });
            },
            confirm: function (text, callback) {
                confirm(text) ? callback() : null;
            }
        },
        files: null,
        addMore: false,
        allowDuplicates: false,
        clipBoardPaste: false,
        onRemove: function(itemEl, file, id, listEl, boxEl, newInputEl, inputEl){
            var filerKit = inputEl.prop("jFiler"),
                file_name = filerKit.files_list[id].name;

            $.post('./php/ajax_remove_file.php', {file: file_name});
        },
        dragDrop: {
            dragEnter: null,
            dragLeave: null,
            drop: null,
            dragContainer: null,
        },
        captions: {
            button: "Choisissez des fichiers",
            feedback: "Choisissez des fichiers à uploader pour cet élément",
            drop: "Drop file here to Upload",
            removeConfirmation: "Are you sure you want to remove this file?",
            errors: {
                filesLimit: "Seulement les fichiers de types {{fi-limit}} sont autorisés ici.",
                filesType: "Seules des images peuvent être uploadées ici.",
                filesSize: "{{fi-name}} est trop large. Le poids maximum est de {{fi-maxSize}} MB.",
                filesSizeAll: "Les fichiers sont trop lourds. Le poids maximum est de {{fi-maxSize}} MB."
            }
        }
    });


/*------------------------------------------------------
    Books upload
------------------------------------------------------*/
    var token = document.getElementById("token").getAttribute('token');
    var resource = $('#booksupload').data('resource');
    var resourceid = $('#booksupload').data('resourceid');
    var extensions = $('#booksupload').data('extensions');

    $("#booksupload").filer({
        limit: 3000,
        maxSize: 200,
        extensions: ['jpg'],
        changeInput: '<div class="jFiler-input-dragDrop"><div class="jFiler-input-inner"><div class="jFiler-input-icon"><i class="icon-jfi-cloud-up-o"></i></div><div class="jFiler-input-text"><h3>Glissez & Déposez vos fichiers ici</h3> <span style="display:inline-block; margin: 15px 0">ou</span></div><a class="jFiler-input-choose-btn btn blue">Cliquez ici</a></div></div>',
        showThumbs: true,
        theme: "dragdropbox",
        templates: {
            box: '<ul class="jFiler-items-list jFiler-items-grid"></ul>',
            item: '<li class="jFiler-item">\
                        <div class="jFiler-item-container">\
                            <div class="jFiler-item-inner">\
                                <div class="jFiler-item-thumb">\
                                    <div class="jFiler-item-status"></div>\
                                    <div class="jFiler-item-thumb-overlay">\
                                        <div class="jFiler-item-info">\
                                            <div style="display:table-cell;vertical-align: middle;">\
                                                <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name}}</b></span>\
                                                <span class="jFiler-item-others">{{fi-size2}}</span>\
                                            </div>\
                                        </div>\
                                    </div>\
                                    {{fi-image}}\
                                </div>\
                                <div class="jFiler-item-assets jFiler-row">\
                                    <ul class="list-inline pull-left">\
                                        <li>{{fi-progressBar}}</li>\
                                    </ul>\
                                    <ul class="list-inline pull-right">\
                                        <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                                    </ul>\
                                </div>\
                            </div>\
                        </div>\
                    </li>',
            itemAppend: '<li class="jFiler-item">\
                            <div class="jFiler-item-container">\
                                <div class="jFiler-item-inner">\
                                    <div class="jFiler-item-thumb">\
                                        <div class="jFiler-item-status"></div>\
                                        <div class="jFiler-item-thumb-overlay">\
                                            <div class="jFiler-item-info">\
                                                <div style="display:table-cell;vertical-align: middle;">\
                                                    <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name}}</b></span>\
                                                    <span class="jFiler-item-others">{{fi-size2}}</span>\
                                                </div>\
                                            </div>\
                                        </div>\
                                        {{fi-image}}\
                                    </div>\
                                    <div class="jFiler-item-assets jFiler-row">\
                                        <ul class="list-inline pull-left">\
                                            <li><span class="jFiler-item-others">{{fi-icon}}</span></li>\
                                        </ul>\
                                        <ul class="list-inline pull-right">\
                                            <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                                        </ul>\
                                    </div>\
                                </div>\
                            </div>\
                        </li>',
            progressBar: '<div class="bar"></div>',
            itemAppendToEnd: false,
            canvasImage: true,
            removeConfirmation: true,
            _selectors: {
                list: '.jFiler-items-list',
                item: '.jFiler-item',
                progressBar: '.bar',
                remove: '.jFiler-item-trash-action'
            }
        },
        uploadFile: {
            url: "/cockpit/upload-books",
            data : { 'X-CSRF-TOKEN' : token, 'resource' : resource, 'resourceid': resourceid },
            type: 'POST',
            enctype: 'multipart/form-data',
            synchron: true,
            beforeSend: function(){},
            success: function(data, itemEl, listEl, boxEl, newInputEl, inputEl, id){
                var parent = itemEl.find(".jFiler-jProgressBar").parent(),
                    new_file_name = JSON.parse(data),
                    filerKit = inputEl.prop("jFiler");

                filerKit.files_list[id].name = new_file_name;

                itemEl.find(".jFiler-jProgressBar").fadeOut("slow", function(){
                    $("<div class=\"jFiler-item-others text-success\"><i class=\"icon-jfi-check-circle\"></i> Succès</div>").hide().appendTo(parent).fadeIn("slow");
                });
            },
            error: function(el){
                var parent = el.find(".jFiler-jProgressBar").parent();
                el.find(".jFiler-jProgressBar").fadeOut("slow", function(){
                    $("<div class=\"jFiler-item-others text-error\"><i class=\"icon-jfi-minus-circle\"></i> Erreur</div>").hide().appendTo(parent).fadeIn("slow");
                });
            },
            statusCode: null,
            onProgress: null,
            onComplete: null
        },
        dialogs: {
            alert: function(text) {
                swal({
                    type: "warning",
                    title: 'Woops',
                    text: text,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Okay',
                    closeOnConfirm: true
                });
            },
            confirm: function (text, callback) {
                confirm(text) ? callback() : null;
            }
        },
        files: null,
        addMore: false,
        allowDuplicates: false,
        clipBoardPaste: false,
        onRemove: function(itemEl, file, id, listEl, boxEl, newInputEl, inputEl){
            var filerKit = inputEl.prop("jFiler"),
                file_name = filerKit.files_list[id].name;

            $.post('./php/ajax_remove_file.php', {file: file_name});
        },
        dragDrop: {
            dragEnter: null,
            dragLeave: null,
            drop: null,
            dragContainer: null,
        },
        captions: {
            button: "Choisissez des fichiers",
            feedback: "Choisissez des fichiers à uploader pour cet élément",
            drop: "Drop file here to Upload",
            removeConfirmation: "Are you sure you want to remove this file?",
            errors: {
                filesLimit: "Seulement les fichiers de types {{fi-limit}} sont autorisés ici.",
                filesType: "Seules des images peuvent être uploadées ici.",
                filesSize: "{{fi-name}} est trop large. Le poids maximum est de {{fi-maxSize}} MB.",
                filesSizeAll: "Les fichiers sont trop lourds. Le poids maximum est de {{fi-maxSize}} MB."
            }
        }
    });


/*------------------------------------------------------
    Tabs
------------------------------------------------------*/
    $('a[data-toggle="tab"]').on('show.bs.tab', function(e) {
        localStorage.setItem('activeTab', $(e.target).attr('href'));
    });

    var activeTab = localStorage.getItem('activeTab');
    
    if(activeTab) {
        $('#myTab a[href="' + activeTab + '"]').tab('show');
    }

    // $('#medias-tab').on('click', function() {
    //     $('#vignette').toggle("slide", { direction: "right" }, 200);
    //     $('#content').toggleClass("col-md-12 col-md-8");
    // });
});


function removeSelectedMedia(folder, id, filename, mediaid, mediafolder) {
    var url = '/cockpit/deletemedia';
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url + (url.indexOf('?')===-1?'?':':') + 'folder=' + folder + '&id=' + id + '&filename=' + filename + '&mediaid=' + mediaid + '&mediafolder=' + mediafolder, true);
    xhr.send();

    $('#'+mediaid).remove();
}

function removemedia(folder, id, filename, mediaid, mediafolder) {
    swal({
        title: "Êtes-vous sûr?",
        text: "Vous allez supprimer le fichier "+filename+" de manière définitive et ne pourra pas être récupéré!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Oui, je suis sûr!',
        cancelButtonText: 'Annuler',
        closeOnConfirm: false
    },
    function(){
        removeSelectedMedia(folder, id, filename, mediaid, mediafolder);
        swal({
            title: "Super!",
            text: "Le média a été supprimée avec succès!",
            type: "success",
            showConfirmButton: false,
            timer: 2000
        });
    });
}

/*------------------------------------------------------
    Delete resource
------------------------------------------------------*/
    function removeSelectedResource(id) {
        var element = $('#' + id);

        var url = element.data('url');
        var xhr = new XMLHttpRequest();
        xhr.open('DELETE', url, true);

        var token = document.getElementById("token").getAttribute('token');
        if(token) {
            xhr.setRequestHeader('X-CSRF-TOKEN', token); // adds directly to the XmlHttpRequest Object
        }

        xhr.send();
    }

    function removeresource(id) {
        swal({
            title: "Êtes-vous sûr?",
            text: "Vous allez supprimer cet élément de manière définitive et ne pourra pas être récupéré!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Oui, je suis sûr!',
            cancelButtonText: 'Annuler',
            closeOnConfirm: false
        },
        function(){
            removeSelectedResource(id);

            swal({
                title: "Super!",
                text: "L'élément a été supprimée avec succès!",
                type: "success",
                showConfirmButton: false,
                timer: 4000
            });

            setTimeout(function() {
                location.reload();
            }, 4100);
        });
    }
